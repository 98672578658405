<template>
  <li
    :class="['sidebar-item', `${exact ? 'exact-matched' : 'partial-matched'}`]"
    @click="$emit('click')"
  >
    <component
      :is="isRouterLink"
      class="item-row"
      v-if="!$slots.default"
      :to="routerLink ? to : null"
    >
      <slot name="icon"></slot>
      <i v-if="iconClass && !$slots.icon" :class="iconClass" class="icon"></i>
      <label>{{ label }}</label>
    </component>
    <slot></slot>
  </li>
</template>

<script>
/**
 * Sidebar item to put in a sidebar.
 *
 * @to the route to navigate after clicking on item.
 * @iconClass class used for the icon.
 * @label the display name for the item.
 */
import "./style/sidebar.scss";

export default {
  components: {},
  mixins: [],
  props: {
    to: {
      type: [String, Object],
      default: ""
    },
    iconClass: {
      type: String,
      default: ""
    },
    label: {
      type: String
    },
    hasDropdown: {
      type: Boolean,
      default: false
    },
    routerLink: {
      type: Boolean,
      default: true
    },
    exact: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {};
  },
  computed: {
    isRouterLink() {
      return this.routerLink ? "router-link" : "span";
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
